<template>
  <div class="popup">
    <div class="popup-back"></div>
    <div class="container">
      <div class="inner">
        <div class="title">{{ labels.you_need_sign_contracts }}</div>
        <div class="cards">
          <div
            v-for="(contract, index) in contracts"
            :key="index"
            class="d-flex justify-content-between card-contract"
          >
            <div style="width: 100%">
              <div class="card-title">
                <span>{{ contract.title }}</span>
                <Button
                  variant="btn-orange"
                  :label="labels.sign"
                  :action="() => sign(contract._id)"
                />
              </div>
              <div v-html="contract.text"></div>
              <div class="card-date">
                {{ labels.date_of_effect }}: {{ formatDateTime(contract.dateOfEffect) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const moment = require("moment");

import { mapState, mapActions, mapGetters } from "vuex";
import Button from "@/components/Buttons/Button";

export default {
  name: "Notes",
  components: {
    Button,
  },
  props: {
    routeBack: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showPopup: true,
    };
  },
  computed: {
    ...mapState("helper", {
      contracts: "contracts",
    }),
    ...mapGetters("helper", ["labels"]),
  },
  methods: {
    ...mapActions("contract", ["signContract"]),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    onModalClose() {
      if (this.routeBack && Object.keys(this.routeBack).length) {
        this.$router.push(this.routeBack);
      } else {
        this.$router.back();
      }
      let element = document.querySelector(".modal-backdrop");
      if (element) {
        element.remove();
        document.querySelector("body").classList.remove("modal-open");
      }
    },
    sign(id) {
      let baseUrl = "/api/ra";

      if (this.$route.name === "account-contracts-sign") baseUrl = "/api/r";

      this.signContract({ id: id, baseUrl: baseUrl })
        .then((res) => {
          if (res.success) {
            this.addNotification({
              variant: "success",
              msg: ["saved"],
              labels: this.labels,
              dismissSecs: 5,
            });
            this.onModalClose();
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    formatDateTime(value) {
      const convertedDateTime = moment(value)
        .utc()
        .format("DD-MM-YYYY HH:mm");
      
      return convertedDateTime;
    }
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba($color: $black, $alpha: 0.5);
  z-index: 1000000;
  top: 0;
  left: 0;
  display: flex;
  //   align-items: center;
  padding: 50px;
  justify-content: center;
  .inner {
    background-color: $white;
    border-radius: calc(0.3rem - 1px);
    min-height: 300px;
    max-height: 500px;
    overflow: hidden;
    box-shadow: inset 0px -10px 20px -1px rgba(0, 0, 0, 0.1);

    .title {
      border-bottom: 1px solid #dee2e6;
      padding: 20px 15px;
    }
    .cards {
      padding: 15px;
      overflow-y: scroll;
      height: 430px;
      &::-webkit-scrollbar {
        width: 0;
      }
    }
    .card-contract {
      border-radius: calc(0.3rem - 1px);
      padding: 15px;
      box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, 0.1);
      margin: 15px 0;
      .card-title {
        font-weight: 600;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      .card-date {
        margin-top: 15px;
        font-size: 14px;
      }
    }
  }
}
</style>
